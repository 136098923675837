
import { useEffect, useState } from 'react';
import MinusIcon from  '../../../resources/img/minus-icon.png';
import PlusIcon from "../../../resources/img/plus-icon.svg";

import './accordion.scss';

interface AccordionItem {
    id: string;
    label: string;
    renderContent: () => JSX.Element;
    toggled?: boolean;
}

interface AccordionProps {
    items: AccordionItem[];
    keepOthersOpen: boolean;
}

const Accordion: React.FC<AccordionProps> = ({ items, keepOthersOpen }) => {
    const [accordionItems, setAccordionItems] = useState<AccordionItem[]>([]);

    useEffect(() => {
        if (items) {
            setAccordionItems(
                items.map(item => ({
                    ...item,
                    toggled: false,
                }))
            );
        }
    }, [items]);

    const handleAccordionToggle = (clickedItem: AccordionItem) => {
        setAccordionItems(prevItems =>
            prevItems.map(item => {
                let toggled = item.toggled;

                if (clickedItem.id === item.id) {
                    toggled = !item.toggled;
                } else if (!keepOthersOpen) {
                    toggled = false;
                }

                return {
                    ...item,
                    toggled,
                };
            })
        );
    };

    return (
        <div className='accordion'>
            {accordionItems.map((listItem) => (
                <div className={`accordion__item ${listItem.toggled ? 'toggled' : ''}`} key={listItem.id}>
                    <div className={`accordion__header ${listItem.toggled ? 'toggled' : ''}`}>
                        <p className='accordion__arrow'>→</p>
                        <button className='accordion__toggle' onClick={() => handleAccordionToggle(listItem)}>
                            <p>{listItem.label}</p>
                            <div className='accordion__direction-indicator'>
                                <p className='accordion__sign-indicator'>{listItem.toggled ?
                                    (<img src={MinusIcon} alt="minus-icon"/>)
                                    : (<img src={PlusIcon} alt="plus-icon"/>)}
                                </p>
                            </div>
                        </button>
                    </div>
                    <div className='accordion__content-container'>
                        <div className='accordion__content'>{listItem.renderContent()}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;
