import './footerLeftBlock.scss';

const LeftBlock: React.FC = () => {
    return (
        <section className='footer-left-block'>

            <div className='footer-left-block__contact' id='contact'>
                <a href='mailto:team@in-sync.agency' className='footer-left-block__contact-email'>TEAM@IN-SYNC.AGENCY</a>
                <a href='https://calendly.com/mariia-myroshnichenko/design-intro-meeting?month=2024-10' target="_blank" className='footer-left-block__contact-call'>BOOK A CALL</a>
            </div>

            <div className='footer-left-block__policy'>
                <p className='footer-left-block__policy-motto'>Driven by craft, culture and imagination.</p>
                <p className='footer-left-block__policy-message'>©2024 All rights reserved. <a href='#!'>Privacy policy</a>, <a href='#!'>Cookie</a></p>
            </div>

        </section>
    );
}

export default LeftBlock;