import './featuredCard.scss';

interface FeaturedCardProps {
    type: 'video' | 'image';
    src: string;
    alt?: string;
    desc: string;
    size: 'large' | 'medium' | 'small' | 'standard' | 'tall';
    hasReports?: boolean;
    descWidth: 'wide' | 'narrow';
    scaleValue? : number;
    objectPositioning? : string;
    rotate?: number;
    hasLabel?: boolean
}

const FeaturedCard: React.FC<FeaturedCardProps> = ({ type, src, alt, desc, size, hasReports, descWidth, scaleValue = 1,  objectPositioning= '50% 50%', rotate=0, hasLabel=false}) => {
    console.log('desc', desc, 'scaleValue', scaleValue, 'objectPositioning', objectPositioning);
    return (
        <article className={`featured-works__card`}>
            {type === 'video' ? (
                <div className={`featured-works__video featured-works__video--${size}`}>
                    <video src={src} className='featured-works__image featured-works__image--full' 
                        autoPlay 
                        loop 
                        muted 
						playsInline/>
                    {hasReports && <img src='../../resources/img/reports-booffer.png' alt='reports' className='featured-works__image--reports' loading='lazy'/>}
                </div>
            ) : (
                <div className='featured-works__image-wrapper'>
                    <img src={src} alt={alt} className={`featured-works__image featured-works__image--${size}`} loading='lazy'
                         style={{transform: `scale(${scaleValue})`}}/>
                </div>
            )}

            <a href='#!' className={`featured-works__desc ${descWidth}`}>
                <p>{desc}</p>
                <p className='featured-works__arrow'>→</p>
            </a>
        </article>
    );
};

export default FeaturedCard;