import { useRef } from 'react';
import ContactUs from '../../ui/contactUs/ContactUs';
import NavLink from '../../ui/navLink/NavLink';

import './appHeader.scss';

interface IProps_AppHeader {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const AppHeader: React.FC<IProps_AppHeader> = () => {
    const navigationRef = useRef<HTMLUListElement>(null);

    const toggleNavigation = () => {
        if (navigationRef.current) {
            navigationRef.current.classList.toggle('responsive');
        }
    };

    return (
        <header className='app-header'>

            <div className='header__left'>
                <p>In Sync agency.</p>
            </div>

            <div className='header__right'>

                <nav className='nav' ref={navigationRef}>
                    <ul className='nav__list'>
                        <span className='nav__logo'/>
                        <li className='nav__item'>
                            <NavLink variant='light' url='#works' text='Works, '/>
                        </li>
                        <li className='nav__item'>
                            <NavLink variant='light' url='#agency' text='Agency, '/>
                        </li>
                        <li className='nav__item'>
                            <NavLink variant='light' url='#services' text='Services, '/>
                        </li>
                        <li className='nav__item'>                
                            <NavLink variant='light' url='#insights' text='Insights'/>
                        </li>
                    </ul>
                </nav>

                <div className='contact'>
                    <ContactUs/>
                </div>

                <button className='menu-button' onClick={toggleNavigation}>
                    <span className='menu-button__bullet'>&bull;</span>
                    <p className='menu-button__text'>Menu</p>
                </button>

            </div>
        </header>
    );
}

export default AppHeader;
