import React, { useEffect, useRef, useState } from 'react';
import './mainScreen.scss';
import sync from '../../resources/video/in-sync-main.mov';

import ReadMore from '../../ui/readMore/ReadMore';

interface MainScreenProps {
	mainRef: React.RefObject<HTMLDivElement>;
	setScrollable: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainScreen: React.FC<MainScreenProps> = ({ mainRef, setScrollable }) => {

	const videoRef = useRef<HTMLVideoElement>(null);
	const mainScreenRef = useRef<HTMLDivElement>(null);
	const [translateY, setTranslateY] = useState(0);
	const [bottomTranslateY, setBottomTranslateY] = useState(0);

	const calculateTranslateY = (baseTranslate: number, increment: number, maxTranslate: number): number => {
		return Math.min(baseTranslate + translateY * increment, maxTranslate);
	};

	const calculateBottomTranslateY = (baseTranslate: number, increment: number, maxTranslate: number): number => {
		return Math.min(baseTranslate + bottomTranslateY * increment, maxTranslate);
	};

	const animateVideo = () => {
		if (!mainScreenRef.current || !videoRef.current) return;

		const { bottom, top } = mainScreenRef.current.getBoundingClientRect();
		const scale = Math.min(1, Math.max(0.3, maxScale - ((bottom - window.innerHeight) * 0.001)));
		videoRef.current.style.transform = `scale(${scale})`;

		setTranslateY((window.innerHeight - top) * intervalTranslate);
		setBottomTranslateY((window.innerHeight - top * 30) * 0.01);
  	};

	let maxTranslate = 0;
	let intervalTranslate = 0;
	let maxScale = 0;

	if (window.innerWidth > 768) {
		maxTranslate = 450;
		intervalTranslate = 0.04;
		maxScale = 1.03;
	} else {
		maxTranslate = 550;
		intervalTranslate = 0.02;
		maxScale = 1.05;
	}


  	useEffect(() => {

		let initialTranslate = 0;

		if (window.innerWidth > 768) {
			initialTranslate = 30;
		} else {
			initialTranslate = 20;
		}

		if (window.innerWidth > 480 && window.innerHeight > 500) {

			const pElements = document.querySelectorAll('.main-screen__cascade p');	

			const handleScroll = () => {
				animateVideo();
			};

			const timer = setTimeout(() => {
				setTranslateY(initialTranslate);
			}, 5600);

			const transitionTimer = setTimeout(() => {
				pElements.forEach(p => {
					(p as HTMLElement).style.transition = '0.3s linear';
				});
				setScrollable(true);
			}, 6700);

			if (mainRef.current) {
				mainRef.current.addEventListener('scroll', handleScroll);
			}

			return () => {
				clearTimeout(timer);
				clearTimeout(transitionTimer);

				if (mainRef.current) {
				mainRef.current.removeEventListener('scroll', handleScroll);
				}
			};

		} else {
			setTimeout(() => {
				setTranslateY(initialTranslate);
			}, 7000);

			setTimeout(() => {
				setScrollable(true);
			}, 8200);
		}
		
	}, [mainRef, setScrollable]);

  return (
    <>
		<section className='main-screen' ref={mainScreenRef} id='top'>
			<div className="main-screen__sticky">
				<div className='main-screen_main-container'>
					<div className='main-screen__video-container'>
						<video
							className="main-screen__video"
							ref={videoRef}
							autoPlay
							muted
							loop
							playsInline
							src={sync}
						/>
					</div>

					<div className='main-screen__cascade-wrapper'>
						<div className='main-screen__cascade'>
							<p style={{ transform: `translateY(${Math.max(-35 - translateY / 5, -40)}px)`}} >IN SYNC <span>AGENCY</span></p>

							{[...Array(10)].map((_, i) => (
								<p key={i} style={{ transform: `translateY(${calculateTranslateY(-35, i + 1, maxTranslate)}px)` }} fetchpriority='high'>IN SYNC <span>AGENCY</span></p>
							))}

						</div>
					</div>
				</div>

				<div className='main-screen__help-container'>
					<div className='main-screen__help' style={{ transform: `translateY(${calculateBottomTranslateY(0, 1.2, 600)}px)` }}>
						<div className='main-screen__help-talk'>
							<ReadMore variant='dark' target='_blank' url='https://calendly.com/mariia-myroshnichenko/design-intro-meeting' text="Let's talk today"/>
						</div>
						<div className='main-screen__help-how'>
							<p>Helping Digital Products Scale with<br/>Strategic, Research-Driven UX/UI
							</p>
							<span>↓</span>
						</div>
					</div>
				</div>
			</div>
		</section>

		<div className='main-screen__mobile-container'>
			<a href='#!' className='main-screen__showreel'> 
				Showreel 2024
				<span>→</span>
			</a>
			<video
				className="main-screen__video-mobile"
				autoPlay
				muted
				loop
				playsInline
				src={sync}
			/>
		</div>
	</>
  );
};

export default MainScreen;
