import './readMore.scss';

interface ReadMoreProps {
    variant: 'light' | 'dark';
    url: string;
    text: string;
    target?: string;
}

const ReadMore: React.FC<ReadMoreProps> = ({ variant, url, text, target='_self' }) => {
    return (
        <a href={url} target={target} className={`read-more__link ${variant}`}>{text}</a>
    );
}

export default ReadMore;
