import './navLink.scss';

interface NavLinkProps {
    variant: 'light' | 'dark';
    url: string;
    text: string;
}

const NavLink: React.FC<NavLinkProps> = ({ variant, url, text }) => {
    return (
        <nav className='nav-link'>
            <a href={url} className={`nav-link__link ${variant}`}>{text}</a>
        </nav>
    );
}

export default NavLink;
