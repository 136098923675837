import ReadMore from '../../../ui/readMore/ReadMore';

import './newsHeader.scss';

const NewsHeader = () => {
    return (
        <div className='news-header'>
            <p className='news-header__title'>Studio insights</p>
            {/*<div className='news-header__cta'>*/}
            {/*    <ReadMore variant='light' url='#!' text='Read more news'/>*/}
            {/*</div>*/}
        </div>
    )
}

export default NewsHeader;