import logo from '../../../resources/img/get-in-sync_mobile.webp';

import './footerMobile.scss';

const FooterMobile: React.FC = () => {
    return (
        <section className='footer-mobile'>

            <div className='footer-mobile__content'>
                <p className='footer-mobile__heading'>Interested in working with us?</p>
            </div>

            <div className='footer-mobile__cta'>
                <button className='footer-mobile__get'><a href="https://calendly.com/mariia-myroshnichenko/design-intro-meeting?month=2024-10" target="_blank"><img src={logo} alt='logo'></img></a></button>
            </div>

            <div className='footer-mobile__contact'>
                <a href='mailto:team@in-sync.agency' target="_blank" className='footer-mobile__contact-email'>TEAM@IN-SYNC.AGENCY</a>
                <a href='https://calendly.com/mariia-myroshnichenko/design-intro-meeting?month=2024-10' target="_blank" className='footer-mobile__contact-call'>BOOK A CALL</a>
            </div>

            <div className='footer-mobile__ratings'>
                <div className='footer-mobile__ratings-item'>
                    <p className='footer-mobile__ratings-title'>TOP RATED PLUS</p>
                    <a href='https://www.upwork.com/o/companies/~01e343f211abd95a7d/' target="_blank" className='footer-mobile__ratings-source'>UPWORK</a>
                </div>
                <div className='footer-mobile__ratings-item'>
                    <p className='footer-mobile__ratings-score'>5.0 ★★★★★</p>
                    <a href='https://clutch.co/profile/sync-0?_gl=1*18vde21*_ga*ODAzMTg4NDQ3LjE2NTY1MDA5OTA.*_ga_D0WFGX8X3V*MTY1ODc0ODA0NS4xNC4xLjE2NTg3NDgwODYuMTk.#summary' target="_blank" className='footer-mobile__ratings-source'>CLUTCH</a>
                </div>
            </div>

            <div className='footer-mobile__contact'>
                <a href='https://www.instagram.com/insync.designteam/?igshid=YmMyMTA2M2Y%3D' target="_blank"
                   className='footer-mobile__social-link'>Instagram</a>
                <a href='https://www.linkedin.com/company/insync-design-team/' target="_blank"
                   className='footer-mobile__social-link'>LinkedIn</a>
                <a href='https://dribbble.com/insync_designteam' target="_blank" className='footer-mobile__social-link'>Dribbble</a>
            </div>

            <div className='footer-mobile__policy'>
                <p className='footer-mobile__policy-motto'>Driven by craft, culture and imagination.</p>
                <p className='footer-mobile__policy-message'>©2024 All rights reserved. <br/> <a href='#!'>Privacy
                    policy</a>, <a href='#!'>Cookie</a></p>
            </div>
        </section>
    );
}

export default FooterMobile;