import './diverseFields.scss';

const DiverseFields: React.FC = () => {
    return (
        <section className="diverse-fields" id='diverse-fields'>

            <p className="diverse-fields__title">Diverse fields</p>

            <div className="diverse-fields__columns">
                <ul className="diverse-fields__list">
                    <li>AI</li>
                    <li>ML & NLP</li>
                    <li>Greentech</li>
                    <li>Web 3.0</li>
                    <li>ART</li>
                    <li>Healthcare</li>
                    <li>Entertainment</li>
                </ul>
                <ul className="diverse-fields__list">
                    <li>E-commerce</li>
                    <li>Retail</li>
                    <li>MarTech</li>
                    <li>GreenTech</li>
                    <li>FinTech</li>
                    <li>HRTech</li>
                    <li>EdTech</li>
                </ul>
            </div>
        </section>
    )
}

export default DiverseFields;