import AppFooter from "../appFooter/AppFooter";
import AboutUs from "../aboutUs/AboutUs";
import FeaturedWorks from "../featuredWorks/FeaturedWorks";
import AppHeader from "../appHeader/AppHeader";
import SplashScreen from "../splashScreen/SplashScreen";
import OurTeam from "../ourTeam/OurTeam";
import NewsSection from "../news/NewsSection";
import DiverseFields from "../diverseFields/DiverseFields";
import Testimonials from "../testimonials/Testimonials";
import OurCapabilities from "../ourCapabilities/OurCapabilities";
import MainScreen from "../mainScreen/MainScreen";

import { useRef, useState} from "react";

const App: React.FC = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [scrollable, setScrollable] = useState(false);

    return (
      <main className={`main ${scrollable ? 'scrollable' : ''}`} ref={mainRef}>
        <MainScreen mainRef={mainRef} setScrollable={setScrollable} />
        <SplashScreen/> 
        <AppHeader/>
        <AboutUs/>
        <FeaturedWorks/>
        <DiverseFields/>        
        <OurCapabilities keepOthersOpen={true}/>
        <Testimonials/>
        <OurTeam/>
        <NewsSection/>
        <AppFooter/>
      </main>
    );
  };
  
export default App;