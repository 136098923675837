import './backToTop.scss';

const BackToTop: React.FC = () => {
    return (
        <nav className='back-to-top'>
            <a href='#top' className="back-to-top__link">Back to top <span className='back-to-top__arrow'>↑</span></a>      
        </nav>
    );
}

export default BackToTop;
