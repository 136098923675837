import './contactUs.scss'

const ContactUs: React.FC = () => {
    return (
        <div className='contact-us'>
            <a href="#contact" className="contact-us__link">Contact Us</a>
        </div>
    )
}

export default ContactUs;