import BackToTop from '../../../ui/backToTop/BackToTop';

import logo from '../../../resources/img/logo.svg'
import buttonLogo from  '../../../resources/img/desktop-footer-logo.svg'
import './footerBanner.scss';

const FooterBanner = () => {



    return (
        <section className='footer-banner'>

            <div className='footer-banner__content'>
                <p className='footer-banner__heading'>Interested in working with us?</p>
                    <a href="https://calendly.com/mariia-myroshnichenko/design-intro-meeting" target="_blank" className='footer-banner-button__link'>
                        <img src={buttonLogo} alt='logo' className='footer-banner-button__logo'/>
                    </a>
            </div>
        </section>
    )
}

export default FooterBanner;