import { useEffect, useRef} from 'react';
import './splashScreen.scss';

const SplashScreen: React.FC = () => {

    const splashRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        document.body.classList.add('no-scroll');

        const splashTimer = setTimeout(() => {
            document.body.classList.remove('no-scroll');
            if (splashRef.current) {
                splashRef.current.classList.add('splash-screen--hidden');
            }
        }, 3500);

        return () => clearTimeout(splashTimer);
    }, []);

    return (
        <section className="splash-screen" ref={splashRef} aria-label='Splash Screen'>

                <div className="splash-screen__text-slide">
                    <div className="splash-screen__main-text">IN SYNC</div>
                    <div className="splash-screen__text-wrap">
                        <div className="splash-screen__text">
                            <span key='1'>AGENCY</span>
                            <span key='2'>DESIGN</span>
                            <span key='3'>STRATEGY</span>
                            <span key='4'>CULTURE</span>
                            <span key='5'>PEOPLE</span>
                            <span key='6'>CREATIVITY</span>
                            <span key='7'>VISION</span>
                        </div>
                    </div>
                </div>

            <div className='splash-screen__copyright'>
                <p>Driven by craft, culture and imagination</p>
                <p>©2019––2024, All rights reserved.</p>
            </div>
            
        </section>
    );
}

export default SplashScreen;
