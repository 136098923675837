import React from 'react';
import TitleBlur from './titleBlur/TitleBlur';
import FeaturedCard from './featuredCard/FeaturedCard';
import ReadMore from '../../ui/readMore/ReadMore';
import featuredWorksData from '../../db/featuredData.json'
import './featuredWorks.scss';

type FeaturedWork = {
    type: 'video' | 'image';
    src: string;
    alt?: string;
    desc: string;
    size: 'large' | 'medium' | 'small' | 'standard' | 'tall';
    hasReports?: boolean;
    descWidth: 'wide' | 'narrow';
    objectPositioning: string;
};

const featuredWorks: FeaturedWork[] = featuredWorksData as FeaturedWork[];

const FeaturedWorks: React.FC = () => {
    return (
        <section className='featured-works' id='works'>
            <TitleBlur />

            <div className='featured-works__row'>
                {featuredWorks.slice(0, 2).map((item, index) => (
                    <FeaturedCard key={index} {...item} />
                ))}
            </div>

            <div className='featured-works__row featured-works__row--center'>
                <FeaturedCard key={2} {...featuredWorks[2]} />
            </div>

            <div className='featured-works__row'>
                {featuredWorks.slice(3, 5).map((item, index) => (
                    <FeaturedCard key={index + 3} {...item} />
                ))}
            </div>

            <div className='featured-works__row featured-works__row--center'>
                <FeaturedCard key={5} {...featuredWorks[5]} />
            </div>

            <div className='featured-works__about'>
                <p>
                    <span className='featured-works__about-bullet'/>
                    Delve deeper into the agency's portfolio
                </p>

                <nav className='featured-works__about-links'>
                    {/*<ReadMore variant='light' url='#!' text='Show all projects' />*/}
                    <span></span>
                    <ReadMore variant='light' target='_blank' url='https://calendly.com/mariia-myroshnichenko/design-intro-meeting?month=2024-10' text='Let’s talk today' />
                </nav>
            </div>
        </section>
    );
};

export default FeaturedWorks;