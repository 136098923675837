import './footerRightBlock.scss';
import BackToTop from "../../../ui/backToTop/BackToTop";

const RightBlock: React.FC = () => {
    return (
        <section className='footer-right-block'>

            <div className='footer-right-block__ratings'>
                <div className='footer-right-block__ratings-item'>
                    <p className='footer-right-block__ratings-title'>TOP RATED PLUS</p>
                    <a href='https://www.upwork.com/o/companies/~01e343f211abd95a7d/' target="_blank" className='footer-right-block__ratings-source'>UPWORK</a>
                </div>
                <div className='footer-right-block__ratings-item'>
                    <p className='footer-right-block__ratings-score'>5.0 ★★★★★</p>
                    <a href='https://clutch.co/profile/sync-0?_gl=1*18vde21*_ga*ODAzMTg4NDQ3LjE2NTY1MDA5OTA.*_ga_D0WFGX8X3V*MTY1ODc0ODA0NS4xNC4xLjE2NTg3NDgwODYuMTk.#summary' target="_blank" className='footer-right-block__ratings-source'>CLUTCH</a>
                </div>
            </div>

            <div className='footer-right-block__bottom-row'>
                <div className='footer-right-block__social-media'>
                    <a href='https://www.instagram.com/insync.designteam/?igshid=YmMyMTA2M2Y%3D' target="_blank" className='footer-right-block__social-link'>Instagram, </a>
                    <a href='https://www.linkedin.com/company/insync-design-team/' target="_blank" className='footer-right-block__social-link'> LinkedIn, </a>
                    <a href='https://dribbble.com/insync_designteam' target="_blank" className='footer-right-block__social-link'> Dribbble</a>
                </div>
                <div className='back-to-top__wrapper'>
                    <BackToTop/>
                </div>
            </div>
    </section>
    );
}

export default RightBlock;